import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import ClientCategories from "../components/Clients/ClientCategories"
import ClientList from "../components/Clients/ClientList"
import SEO from "../components/seo"
import heroImage from "../images/clents_bg.jpg"
import { withPreview } from "gatsby-source-prismic"

const ClientCategory = ({ data }) => {
  const category = data.prismicClientCategories.data.label
  const posts = data.allPrismicClients.edges

  const {
    seo_description,
    seo_keywords,
    og_description,
    og_image,
    og_title,
    og_type,
    twitter_creator,
    twitter_description,
    twitter_image_link,
    twitter_title,
  } = data.prismicClientCategories.data
  const seo_title =
    data.prismicClientCategories.data.seo_title ||
    "Brands rely on Vindicia subscription billing & recurring revenue solutions."
  const postNodes = posts.sort(
    (a, b) =>
      new Date(b.node.data.publish_date).getTime() - new Date(a.node.data.publish_date).getTime(),
  )

  return (
    <Layout>
      <SEO
        title={seo_title}
        description={seo_description}
        keywords={seo_keywords}
        ogDescription={og_description}
        image={og_image}
        ogTitle={og_title}
        ogType={og_type}
        twitterTitle={twitter_title}
        twitterCreator={twitter_creator}
        twitterDescription={twitter_description}
        twitterImage={twitter_image_link}
      />
      <Header image={heroImage} title="Over 100 clients all around the globe rely on Vindicia" />
      <ClientCategories
        categories={data.allPrismicClientCategories.edges.map(c => c.node)}
        category={category}
      />
      <section className="uk-section uk-section-xsmall">
        <div className="uk-container">
          <ClientList posts={postNodes} />
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(ClientCategory)

export const clientCategoryQuery = graphql`
  query clientCategoryQuery($uid: String) {
    allPrismicClients(
      filter: { data: { categories: { elemMatch: { category: { uid: { eq: $uid } } } } } }
      sort: { fields: data___company_name, order: ASC }
    ) {
      edges {
        node {
          uid
          data {
            company_name
            company_url {
              url
            }
            description {
              html
              text
            }
            logo {
              fixed(width: 160) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            hero_image {
              fixed(width: 500) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            categories {
              category {
                uid
                document {
                  ... on PrismicClientCategories {
                    data {
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    prismicClientCategories(uid: { eq: $uid }) {
      data {
        label
        og_description
        og_image
        og_title
        og_type
        seo_description
        seo_keywords
        seo_title
        twitter_creator
        twitter_description
        twitter_image_link
        twitter_title
      }
    }
    allPrismicClientCategories {
      edges {
        node {
          data {
            label
          }
          uid
        }
      }
    }
  }
`
